import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import { navigate, RouteComponentProps } from "@reach/router";
import AdminLayout from "components/AdminLayout";
import Counter from "components/Counter";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { getPostById, updateNovelPost } from "services/api";
import { User } from "services/models";

interface MatchingProps {
  postId: string;
  user: User;
  children: React.ReactNode;
}

type Inputs = {
  novelId: string;
  postId: string;
  authorId: string;
  title: string;
  content: string;
  is_published: string;
};

export default function EditPost({
  postId,
  user,
}: RouteComponentProps<MatchingProps>) {
  const { register, control, handleSubmit, setValue } = useForm<Inputs>();
  const onSubmit = (data: Inputs) => {
    if (!postId || !user) return;
    updateNovelPost(
      data.postId,
      data.novelId,
      data.title,
      data.content,
      data.authorId,
      data.is_published === "yes"
    ).then((result) => {
      if (result instanceof Error) {
        alert(result.message);
      } else {
        navigate(`/posts/${result.id}`);
      }
    });
  };
  useEffect(() => {
    if (!postId) return;
    getPostById(postId).then((result) => {
      if (result instanceof Error) {
        alert(result.message);
      } else {
        setValue("postId", result.id);
        setValue("novelId", result.novel.id);
        setValue("title", result.title);
        setValue("content", result.content);
        setValue("is_published", !result.published_at ? "no" : "yes");
      }
    });
  }, [postId, setValue]);

  return (
    <AdminLayout title="회차 수정">
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" ref={register} name="postId" value={postId} />
        <input type="hidden" ref={register} name="novelId" />
        <input type="hidden" ref={register} name="authorId" value={user?.id} />
        <Controller
          as={TextField}
          name="title"
          label="제목"
          defaultValue=""
          fullWidth
          control={control}
        />
        <Controller
          as={TextField}
          name="content"
          label="본문"
          defaultValue=""
          fullWidth
          multiline
          rows="20"
          control={control}
        />
        <Counter control={control} />
        <FormControl component="fieldset">
          <FormLabel component="legend">공개여부</FormLabel>
          <Controller
            control={control}
            name="is_published"
            defaultValue="no"
            render={({ onChange, value }) => (
              <RadioGroup
                row
                aria-label="공개여부"
                onChange={onChange}
                value={value}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="공개"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="비공개"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
        <Box marginTop={2}>
          <Button type="submit" variant="contained" color="primary">
            등록
          </Button>
        </Box>
      </form>
    </AdminLayout>
  );
}
