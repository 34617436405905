import HomeIcon from "@mui/icons-material/Home";
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "@reach/router";

const StyledFab = styled(Fab)(({ theme }) => ({
  position: "fixed",
  bottom: theme.spacing(2),
  left: "50%",
  transform: "translateX(372px)",
  [theme.breakpoints.down("md")]: {
    left: "unset",
    right: theme.spacing(2),
    transform: "unset",
  },
})) as typeof Fab;
export default function FloatingHomeButton() {
  return (
    <StyledFab
      color="primary"
      aria-label="back to list"
      component={RouterLink}
      to="/"
    >
      <HomeIcon />
    </StyledFab>
  );
}
