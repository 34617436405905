import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { navigate, RouteComponentProps } from "@reach/router";
import AdminLayout from "components/AdminLayout";
import Cover from "components/Cover";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { getNovelById, getTags, updateNovel, uploadFile } from "services/api";
import { CoverMedia, Tag, User } from "services/models";

interface MatchingProps {
  novelId: string;
  user: User;
  children: React.ReactNode;
}

type Inputs = {
  novelId: string;
  authorId: string;
  title: string;
  description: string;
  tags: string[];
  cover: string;
};

export default function EditNovel({
  novelId,
  user,
}: RouteComponentProps<MatchingProps>) {
  const { control, register, handleSubmit, setValue } = useForm<Inputs>({
    defaultValues: {
      novelId: "",
      authorId: "",
      title: "",
      description: "",
      tags: [],
    },
  });
  const [cover, setCover] = useState<CoverMedia>({ id: "", url: "" });
  const [tags, setTags] = useState<Tag[]>([]);
  const onChangeFile = async (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.currentTarget as HTMLInputElement;
    if (target.files) {
      const result = await uploadFile(target.files[0]);
      if (result instanceof Error) {
        alert("업로드 실패");
      } else {
        setCover(result);
      }
    }
  };
  const onSubmit = (data: Inputs) => {
    if (!user) return;
    updateNovel(
      data.novelId,
      data.title,
      data.description,
      data.authorId,
      data.tags,
      data.cover
    ).then((result) => {
      if (result instanceof Error) {
        alert(result.message);
      } else {
        navigate(`/novels/${result.id}`);
      }
    });
  };
  useEffect(() => {
    if (!novelId) return;
    getNovelById(novelId).then((result) => {
      if (result instanceof Error) {
        alert(result.message);
      } else {
        setValue("title", result.title);
        setValue("description", result.description);
        setValue(
          "tags",
          result.tags.map((tag) => tag.id)
        );
        setValue("cover", result.cover.id);
        setCover(result.cover);
      }
    });
    getTags().then((results) => {
      setTags(results);
    });
  }, [novelId, setValue]);
  return (
    <AdminLayout title="작품 정보 수정">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={3} sm={4}>
            <FormLabel component="legend">표지 이미지 *</FormLabel>
            <Cover filename={cover.url} />
            <input type="file" onChange={onChangeFile} />
            <input type="hidden" ref={register} name="cover" value={cover.id} />
            <FormHelperText>권장 가로:세로 비율은 1:1.4입니다.</FormHelperText>
          </Grid>
          <Grid item xs={9} sm={8}>
            <input
              type="hidden"
              ref={register}
              name="novelId"
              value={novelId}
            />
            <input
              type="hidden"
              ref={register}
              name="authorId"
              value={user?.id}
            />
            <Controller
              as={TextField}
              name="title"
              label="제목"
              defaultValue=""
              required
              fullWidth
              control={control}
            />
            <Controller
              as={TextField}
              name="description"
              label="소개"
              defaultValue=""
              required
              fullWidth
              multiline
              rows="5"
              control={control}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="demo-multiple-chip-label">태그</InputLabel>
              <Controller
                name="tags"
                required
                control={control}
                render={({ onChange, value }) => (
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={value}
                    input={
                      <OutlinedInput id="select-multiple-chip" label="Chip" />
                    }
                    onChange={(event: SelectChangeEvent<string[]>) => {
                      const {
                        target: { value },
                      } = event;
                      onChange(
                        // On autofill we get a the stringified value.
                        typeof value === "string" ? value.split(",") : value
                      );
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((tagId) => (
                          <Chip
                            key={tagId}
                            label={`#${
                              tags.find((tag) => tag.id === tagId)?.title
                            }`}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {tags.map((tag) => (
                      <MenuItem key={tag.id} value={tag.id}>
                        {tag.title}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <Typography>
              작품 정보 수정시 비공개 상태가 되며 공개 설정은 관리자만
              가능합니다.
            </Typography>
            <Box marginTop={2}>
              <Button type="submit" variant="contained" color="primary">
                등록
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </AdminLayout>
  );
}
