import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { navigate, RouteComponentProps } from "@reach/router";
import AdminLayout from "components/AdminLayout";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { register } from "services/api";
import { User } from "services/models";

interface RegisterProps {
  onRegister: (user: User) => void;
}
type Inputs = {
  email: string;
  password: string;
  display_name: string;
  affiliation: string;
};

export default function Register({
  onRegister,
}: RouteComponentProps<RegisterProps>) {
  const { control, handleSubmit } = useForm<Inputs>();
  const [error, setError] = useState("");
  const onSubmit = (data: Inputs) => {
    if (!data.email || !data.password || !data.display_name) {
      alert("필수 입력 항목을 확인하세요.");
      return;
    }
    register(
      data.email,
      data.password,
      data.display_name,
      data.affiliation
    ).then((result) => {
      if ("jwt" in result) {
        alert("가입 신청 완료. 관리자 승인을 기다리세요.");
        if (onRegister) onRegister(result.user);
        navigate(`/`);
      }
      if ("error" in result) {
        setError(result.error);
      }
    });
  };
  return (
    <Grid container justifyContent="center">
      <Grid item xs={6}>
        <AdminLayout title="가입신청">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              as={TextField}
              name="email"
              label="아이디(이메일) *"
              defaultValue=""
              fullWidth
              control={control}
              rules={{ required: true }}
            />
            <Controller
              as={TextField}
              type="password"
              name="password"
              label="패스워드(8자 이상) *"
              defaultValue=""
              fullWidth
              control={control}
              rules={{ required: true, min: 8 }}
            />
            <Controller
              as={TextField}
              name="display_name"
              label="이름(필명) *"
              defaultValue=""
              fullWidth
              control={control}
              rules={{ required: true }}
            />
            <Controller
              as={TextField}
              name="affiliation"
              label="소속"
              defaultValue=""
              fullWidth
              control={control}
            />
            <div>{error}</div>
            <Box marginTop={2}>
              <Button type="submit" variant="contained" color="primary">
                가입신청 (관리자 승인 후 완료됩니다)
              </Button>
            </Box>
          </form>
        </AdminLayout>
      </Grid>
    </Grid>
  );
}
