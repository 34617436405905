import { RouteComponentProps } from "@reach/router";
import React from "react";
import { User } from "services/models";
import Footer from "./Footer";
import Header from "./Header";

interface MainProps {
  user: User;
  children: React.ReactNode;
}

export default function Main(props: RouteComponentProps<MainProps>) {
  return (
    <>
      <Header user={props.user} />
      <main>{props.children}</main>
      <Footer />
    </>
  );
}
