import { styled } from "@mui/material/styles";
import React from "react";
import { getImageUrl } from "services/api";

const PREFIX = "Cover";

const classes = {
  coverImageWrapper: `${PREFIX}-coverImageWrapper`,
  coverImage: `${PREFIX}-coverImage`,
};

const Root = styled("div")(({ theme: Theme }) => ({
  [`&.${classes.coverImageWrapper}`]: {
    position: "relative",
    width: "100%",
    paddingTop: "140%",
  },

  [`& .${classes.coverImage}`]: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

interface CoverProps {
  filename: string;
}

export default function Cover(props: CoverProps) {
  return (
    <Root className={classes.coverImageWrapper}>
      <img
        src={getImageUrl(props.filename)}
        className={classes.coverImage}
        alt="작품표지"
      />
    </Root>
  );
}
