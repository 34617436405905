import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import NoSsr from "@mui/material/NoSsr";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import { Router } from "@reach/router";
import React, { useState } from "react";
import Home from "./components/Home";
import Main from "./components/Main";
import Login from "./features/auth/Login";
import Register from "./features/auth/Register";
import SingleAuthor from "./features/author/SingleAuthor";
import AddNovel from "./features/novels/AddNovel";
import EditNovel from "./features/novels/EditNovel";
import SingleNovel from "./features/novels/SingleNovel";
import AddPost from "./features/posts/AddPost";
import EditPost from "./features/posts/EditPost";
import SinglePost from "./features/posts/SinglePost";
import { getUser } from "./services/api";

const theme = createTheme({
  typography: {
    fontFamily: ["Noto Sans KR", "sans-serif"].join(","),
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: "normal",
      },
    },
  },
});

export default function App() {
  const [user, setUser] = useState(getUser());
  return (
    <NoSsr>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Container maxWidth="md">
            <Router>
              <Main path="/" user={user}>
                <Home path="/" />
                <Home path="tags/:tagId" />
                <Home path="search/:search" />
                <Home path="my" user={user} />
                <Login path="login" onLogin={(user) => setUser(user)} />
                <Register
                  path="register"
                  onRegister={(user) => setUser(user)}
                />
                <AddNovel path="new" user={user} />
                <EditNovel path="novels/:novelId/edit" user={user} />
                <SingleNovel path="novels/:novelId" user={user} />
                <AddPost path="novels/:novelId/posts/new" user={user} />
                <EditPost path="posts/:postId/edit" user={user} />
                <SingleAuthor path="authors/:authorId" />
              </Main>
              <SinglePost path="posts/:postId" />
            </Router>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
    </NoSsr>
  );
}
