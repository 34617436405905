import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React, { ReactNode } from "react";

interface LayoutProps {
  title: string;
  children: ReactNode;
}

export default function AdminLayout({ title, children }: LayoutProps) {
  return (
    <Paper>
      <Box padding={2} marginTop={6}>
        <Typography variant="h4" component="h1">
          {title}
        </Typography>
        {children}
      </Box>
    </Paper>
  );
}
