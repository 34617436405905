import ArrowBack from "@mui/icons-material/ArrowBack";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import StarBorder from "@mui/icons-material/StarBorder";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  navigate,
  RouteComponentProps,
  Link as RouterLink,
} from "@reach/router";
import FloatingHomeButton from "components/FloatingHomeButton";
import format from "date-fns/format";
import React, { useEffect, useState } from "react";
import { getPostById } from "services/api";
import { Post } from "services/models";

const PREFIX = "SinglePost";

const classes = {
  menuButton: `${PREFIX}-menuButton`,
  title: `${PREFIX}-title`,
  prev: `${PREFIX}-prev`,
  next: `${PREFIX}-next`,
  navButtonLabel: `${PREFIX}-navButtonLabel`,
  postGrid: `${PREFIX}-postGrid`,
  content: `${PREFIX}-content`,
  infoBox: `${PREFIX}-infoBox`,
  nextPostBox: `${PREFIX}-nextPostBox`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
  },

  [`& .${classes.title}`]: {
    flexGrow: 1,
  },

  [`& .${classes.prev}`]: {
    top: "50vh",
    left: 0,
    position: "fixed",
  },

  [`& .${classes.next}`]: {
    top: "50vh",
    right: 0,
    position: "fixed",
  },

  [`& .${classes.navButtonLabel}`]: {
    flexDirection: "column",
    justifyContent: "center",
    "& span": {
      display: "flex",
    },
  },

  [`& .${classes.postGrid}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.content}`]: {
    wordBreak: "keep-all",
    overflowWrap: "break-word",
  },

  [`& .${classes.infoBox}`]: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },

  [`& .${classes.nextPostBox}`]: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

interface MatchingProps {
  postId: string;
}

function ShowOnClick(props: {
  children: React.ReactElement;
  externalFlag: boolean;
  direction: "down" | "left" | "right";
}) {
  const { children, externalFlag, direction } = props;

  return (
    <Slide appear={false} direction={direction} in={externalFlag}>
      {children}
    </Slide>
  );
}

export default function SinglePost(props: RouteComponentProps<MatchingProps>) {
  const postId = props.postId;

  const [post, setPost] = useState<Post>({
    id: "",
    published_at: "2020-01-01T00:00:00",
    title: "",
    content: "",
    novel: {
      id: "",
      title: "",
      author: {
        id: "",
        display_name: "",
        bio: "",
      },
    },
  });

  const postContent = post.content ? (
    post.content.split("\n").map((p, i) => {
      return (
        <Typography
          key={`${postId}_${i}`}
          variant="body1"
          gutterBottom
          className={classes.content}
        >
          {p}&nbsp;
        </Typography>
      );
    })
  ) : (
    <Skeleton variant="rectangular" width="100%" height={600} />
  );
  const [clickFlag, setClickFlag] = useState<boolean>(false);
  const onClickHandler = function () {
    setClickFlag((c) => !c);
  };

  useEffect(() => {
    if (!postId) return;
    getPostById(postId).then((result) => {
      if (result instanceof Error) {
        alert(result.message);
        navigate("/");
      } else {
        setPost(result);
        setClickFlag(false);
        window.scrollTo(0, 0);
      }
    });
  }, [postId]);

  return (
    <Root>
      <ShowOnClick {...props} direction="down" externalFlag={clickFlag}>
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              className={classes.menuButton}
              component={RouterLink}
              to={`/novels/${post.novel.id}`}
              size="large"
            >
              <ArrowBack />
            </IconButton>
            <Typography variant="h6" component="span" className={classes.title}>
              {post.title}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="favorite"
              size="large"
            >
              <StarBorder />
            </IconButton>
          </Toolbar>
        </AppBar>
      </ShowOnClick>
      <ShowOnClick {...props} direction="right" externalFlag={clickFlag}>
        <Box className={classes.prev}>
          <Button
            variant="contained"
            className={classes.navButtonLabel}
            component={RouterLink}
            to={`/posts/${post.prev_id}`}
            disabled={!post.prev_id}
            replace
          >
            <span>
              <ChevronLeft />
            </span>
            <span>이전화</span>
          </Button>
        </Box>
      </ShowOnClick>
      <ShowOnClick {...props} direction="left" externalFlag={clickFlag}>
        <Box className={classes.next}>
          <Button
            variant="contained"
            className={classes.navButtonLabel}
            component={RouterLink}
            to={`/posts/${post.next_id}`}
            disabled={!post.next_id}
            replace
          >
            <ChevronRight />
            다음화
          </Button>
        </Box>
      </ShowOnClick>
      <Grid
        container
        spacing={4}
        className={classes.postGrid}
        justifyContent="center"
        onClick={onClickHandler}
      >
        <Grid item xs={12} sm={8}>
          <Typography variant="h5" component="h1" gutterBottom>
            {post.title ? post.title : <Skeleton />}
          </Typography>
          {postContent}
          <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
          <Box className={classes.infoBox}>
            <Typography variant="h6" component="p" gutterBottom>
              {post.novel.title ? post.novel.title : <Skeleton />}
            </Typography>
            <Typography variant="body1">
              {`${post.title} | ${
                post.published_at
                  ? format(new Date(post.published_at), "yyyy.MM.dd")
                  : "비공개"
              }`}
            </Typography>
            <Typography variant="body1">
              {post.novel.author.display_name}
            </Typography>
          </Box>
          <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
          <Box mb={2}>
            <Button
              variant="outlined"
              size="large"
              fullWidth
              component={RouterLink}
              to={`/posts/${post.next_id}`}
              disabled={!post.next_id}
              replace
            >
              {post.next_id ? "다음화" : "마지막화입니다"}
            </Button>
          </Box>
          <FloatingHomeButton />
        </Grid>
      </Grid>
    </Root>
  );
}
