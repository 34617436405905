import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";

const PREFIX = "Hero";

const classes = {
  FeaturedNovel: `${PREFIX}-FeaturedNovel`,
  overlay: `${PREFIX}-overlay`,
  FeaturedNovelContent: `${PREFIX}-FeaturedNovelContent`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.FeaturedNovel}`]: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },

  [`& .${classes.overlay}`]: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.3)",
  },

  [`& .${classes.FeaturedNovelContent}`]: {
    position: "relative",
    padding: theme.spacing(3),
    wordBreak: "keep-all",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },
}));

const placeholderNovel = {
  id: 0,
  title: "WebSoSo",
  description: "청강문화산업대학교 만화콘텐츠스쿨 웹소설창작전공",
  image: "/images/hero_bg.jpg",
  imgText: " image description",
  linkText: "홈페이지 가기 →",
  link: "https://webnovel.blog/",
};

export default function FeaturedNovel() {
  const post = placeholderNovel;

  return (
    <StyledPaper
      className={classes.FeaturedNovel}
      style={{ backgroundImage: `url(${post.image})` }}
    >
      {/* Increase the priority of the hero background image */}
      {<img style={{ display: "none" }} src={post.image} alt={post.imgText} />}
      <div className={classes.overlay} />
      <Grid container>
        <Grid item md={6}>
          <div className={classes.FeaturedNovelContent}>
            <Typography
              component="h1"
              variant="h3"
              color="inherit"
              gutterBottom
            >
              {post.title}
            </Typography>
            <Typography variant="h5" color="inherit" paragraph>
              {post.description}
            </Typography>
            <Link
              variant="subtitle1"
              color="inherit"
              href={post.link}
              target="_blank"
            >
              {post.linkText}
            </Link>
          </div>
        </Grid>
      </Grid>
    </StyledPaper>
  );
}
