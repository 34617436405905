import LockIcon from "@mui/icons-material/Lock";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Link as RouterLink, navigate } from "@reach/router";
import { useEffect, useReducer, useState } from "react";
import {
  getImageUrl,
  getNovelsBySearch,
  getNovelsByTag,
  getNovelsByUser,
} from "services/api";
import { Novel, User } from "services/models";

const PREFIX = "NovelList";

const classes = {
  media: `${PREFIX}-media`,
  content: `${PREFIX}-content`,
};

const StyledGrid = styled(Grid)({
  [`& .${classes.media}`]: {
    height: 256,
  },
  [`& .${classes.content}`]: {
    padding: 8,
  },
});

const ITEM_PER_PAGE = 100;
interface BaseQuery {
  skip: number;
  _initial?: boolean;
}
interface TagQuery {
  skip: number;
  _initial?: boolean;
  tagId: string;
}
interface SearchQuery {
  skip: number;
  _initial?: boolean;
  search: string;
}
interface UserQuery {
  skip: number;
  _initial?: boolean;
  userId: string;
}
type Query = BaseQuery | TagQuery | SearchQuery | UserQuery;
type Action =
  | { type: "more" }
  | { type: "tag"; tagId: string }
  | { type: "search"; search: string }
  | { type: "user"; userId: string }
  | { type: "reset" };
const baseQuery: Query = { skip: 0 };
function reducer(state: Query, action: Action): Query {
  switch (action.type) {
    case "more":
      return { ...state, skip: state.skip + ITEM_PER_PAGE, _initial: false };
    case "tag":
      return { ...baseQuery, tagId: action.tagId, _initial: false };
    case "search":
      return { ...baseQuery, search: action.search, _initial: false };
    case "user":
      return { ...baseQuery, userId: action.userId, _initial: false };
    case "reset":
      return baseQuery;
    default:
      return state;
  }
}

export default function NovelList({
  tagId,
  search,
  user,
}: {
  tagId?: string;
  search?: string;
  user?: User;
}) {
  const [novels, setNovels] = useState<Omit<Novel, "posts">[]>([]);

  const [query, dispatch] = useReducer(reducer, {
    ...baseQuery,
    _initial: true,
  });

  const onMoreClick = () => dispatch({ type: "more" });

  useEffect(() => {
    if (search) {
      dispatch({ type: "search", search });
    } else if (tagId) {
      dispatch({ type: "tag", tagId });
    } else if (user) {
      dispatch({ type: "user", userId: user.id });
    } else {
      dispatch({ type: "reset" });
    }
  }, [tagId, search, user]);

  useEffect(() => {
    if (query._initial) return;
    let novels;
    if ("search" in query) {
      novels = getNovelsBySearch(query);
    } else if ("tagId" in query) {
      novels = getNovelsByTag(query);
    } else if ("userId" in query) {
      novels = getNovelsByUser(query);
    } else {
      // novels = getAllNovels(query);
      novels = getNovelsByTag({ skip: 0, tagId: "56" /* 2025 */ }).then(
        (results) => {
          return results.sort(() => Math.random() - 0.5);
        }
      );
    }
    novels.then((results) => {
      if (query.skip > 0) {
        setNovels((novels) => novels.concat(results));
      } else {
        setNovels(results);
      }
    });
  }, [query]);

  if (novels.length < 1) {
    return <span>공개된 작품이 없습니다.</span>;
  }
  return (
    <StyledGrid container spacing={2}>
      {novels.map((novel) => (
        <Grid item xs={6} sm={3} key={novel.id}>
          <Card>
            <CardActionArea onClick={() => navigate(`/novels/${novel.id}`)}>
              <CardMedia
                className={classes.media}
                image={getImageUrl(novel.cover.url)}
              />
              <CardContent className={classes.content}>
                <Link
                  component={RouterLink}
                  to={`/novels/${novel.id}`}
                  color="inherit"
                  underline="none"
                >
                  <Typography variant="body1" component="h3" noWrap>
                    {novel.title}
                    {novel && !novel.published_at && (
                      <LockIcon fontSize="inherit" />
                    )}
                  </Typography>
                  <Typography variant="body2" component="h4" noWrap>
                    {novel.author.display_name || `\u00A0`}
                  </Typography>
                  <Typography variant="caption" component="p" noWrap>
                    {novel.tags.map((tag) => `#${tag.title} `)}
                  </Typography>
                </Link>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Box
          display="none"
          justifyContent="center"
          alignItems="center"
          marginTop={2}
        >
          <Button variant="outlined" size="large" onClick={onMoreClick}>
            더 보기
          </Button>
        </Box>
        {user && user.role === "author" && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={2}
          >
            <Button
              variant="contained"
              size="large"
              color="primary"
              component={RouterLink}
              to={`/new`}
            >
              작품 등록
            </Button>
          </Box>
        )}
      </Grid>
    </StyledGrid>
  );
}
