import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  Link as RouterLink,
  navigate,
  RouteComponentProps,
} from "@reach/router";
import AdminLayout from "components/AdminLayout";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { login } from "services/api";
import { User } from "services/models";

interface LoginProps {
  onLogin: (user: User) => void;
}
type Inputs = {
  email: string;
  password: string;
};

export default function Login({ onLogin }: RouteComponentProps<LoginProps>) {
  const { control, handleSubmit } = useForm<Inputs>();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const onSubmit = (data: Inputs) => {
    setLoading(true);
    login(data.email, data.password).then((result) => {
      if ("jwt" in result) {
        if (onLogin) onLogin(result.user);
        navigate(`/`);
      }
      if ("error" in result) {
        setError(result.error);
        setLoading(false);
      }
    });
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={6}>
        <AdminLayout title="로그인">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              as={TextField}
              name="email"
              label="Email"
              defaultValue=""
              autoComplete="username"
              required
              fullWidth
              control={control}
            />
            <Controller
              as={TextField}
              name="password"
              type="password"
              label="Password"
              defaultValue=""
              autoComplete="current-password"
              required
              fullWidth
              control={control}
            />
            <div>{error}</div>
            <Box marginTop={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                로그인
              </Button>
              {"  "}
              <Button variant="contained" component={RouterLink} to="/register">
                가입신청
              </Button>
            </Box>
          </form>
        </AdminLayout>
      </Grid>
    </Grid>
  );
}
