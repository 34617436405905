import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { RouteComponentProps, Link as RouterLink } from "@reach/router";
import Hero from "components/Hero";
import NovelList from "features/novels/NovelList";
import { Tag, User } from "services/models";

interface MenuTag extends Tag {
  shortTitle?: string;
  parent?: "graduation";
}

const PREFIX = "Home";

const classes = {
  homeGrid: `${PREFIX}-homeGrid`,
  toolbarSecondary: `${PREFIX}-toolbarSecondary`,
  toolbarLink: `${PREFIX}-toolbarLink`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.homeGrid}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.toolbarSecondary}`]: {
    justifyContent: "space-between",
    overflowX: "auto",
  },

  [`& .${classes.toolbarLink}`]: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
}));

const sections: MenuTag[] = [
  { title: "2025년도 졸업작품", shortTitle: "2025", id: "56" },
  { title: "로맨스", id: "1" },
  { title: "로판", id: "50" },
  { title: "BL/GL", id: "12" },
  { title: "판타지", id: "3" },
  { title: "현판", id: "51" },
  { title: "무협", id: "5" },
  { title: "출판장르문학", id: "9" },
  { title: "SF", id: "8" },
  { title: "미스터리/호러", id: "29" },
  { title: "시나리오", id: "10" },
];

export default function Home({
  tagId,
  search,
  user,
}: RouteComponentProps<{ tagId: string; search: string; user: User }>) {
  const currentTag = sections.find((s) => s.id === tagId);
  return (
    <Root>
      <Toolbar
        component="nav"
        variant="dense"
        disableGutters
        className={classes.toolbarSecondary}
      >
        {sections
          .filter((s) => !s.parent)
          .map((section) => (
            <Button
              component={RouterLink}
              color="inherit"
              key={section.id}
              to={`/tags/${section.id}`}
              className={classes.toolbarLink}
            >
              {section.shortTitle || section.title}
            </Button>
          ))}
      </Toolbar>
      {!currentTag && !search && <Hero />}
      <Grid container className={classes.homeGrid}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            {currentTag && currentTag.title}
            {search && `검색어: ${search}`}
            {!currentTag && !search && "2025년도 졸업작품"}
          </Typography>
          <NovelList
            tagId={currentTag && currentTag.id}
            search={search}
            user={user}
          />
        </Grid>
      </Grid>
    </Root>
  );
}
