import { Auth, CoverMedia, Novel, Post, Tag } from "services/models";
import { components } from "services/schema";
import wretch from "wretch";

function setPersistantAuth(auth?: Auth) {
  if (typeof auth === "undefined") {
    return window.sessionStorage.removeItem("WEBSOSO_AUTH");
  }
  window.sessionStorage.setItem("WEBSOSO_AUTH", JSON.stringify(auth));
}
function getPersistantAuth(): Auth | undefined {
  const authString = window.sessionStorage.getItem("WEBSOSO_AUTH");
  if (!authString) return;
  return JSON.parse(authString);
}
const API_BASE = process.env.REACT_APP_API_BASE || "/api";
const api = wretch()
  // Set the base url
  .url(API_BASE);
const authorizedApi = api
  // Userization header
  .options({ credentials: "include", mode: "cors" })
  .resolve((resolver) =>
    resolver
      .forbidden(() => new Error("권한이 없습니다."))
      .unauthorized(() => new Error("로그인이 필요합니다."))
  )
  .defer((w) => {
    const token = getPersistantAuth();
    if (token) {
      return w.auth(`Bearer ${getPersistantAuth()?.jwt}`);
    }
    return w;
  });

/* auth */
export const getUser = () => getPersistantAuth()?.user;

export const login = async (email: string, password: string) => {
  const loginInfo = {
    identifier: email,
    password,
  };
  const result = await api
    .url("/auth/local")
    .formUrl(loginInfo)
    .post()
    .badRequest(() => ({ message: "아이디 또는 패스워드를 확인하세요." }))
    .forbidden(() => ({
      message: "가입 승인이 필요합니다. 관리자에게 문의하세요.",
    }))
    .json<
      | { jwt: string; user: components["schemas"]["UsersPermissionsUser"] }
      | Error
    >();
  if ("jwt" in result) {
    const auth: Auth = {
      jwt: result.jwt,
      user: {
        id: result.user.id,
        email: result.user.email,
        display_name: result.user.username,
        is_active: !!result.user.confirmedByAdmin,
        role: result.user.role?.type === "author" ? "author" : "authenticated",
        bio: result.user.bio || "",
      },
    };
    setPersistantAuth(auth);
    return auth;
  }
  return {
    error: result.message,
  };
};

export const logout = () => {
  setPersistantAuth();
};

export const register = async (
  email: string,
  password: string,
  display_name: string,
  affiliation?: string
) => {
  const loginInfo = {
    email,
    password,
    username: display_name,
    affiliation,
  };
  const result = await api
    .url("/auth/local/register")
    .formUrl(loginInfo)
    .post()
    .badRequest(() => ({ message: "필수 입력 항목을 확인하세요." }))
    .json<
      | { jwt: string; user: components["schemas"]["UsersPermissionsUser"] }
      | Error
    >();
  if ("jwt" in result) {
    const auth: Auth = {
      jwt: result.jwt,
      user: {
        id: result.user.id,
        email: result.user.email,
        display_name: result.user.username,
        is_active: !!result.user.confirmedByAdmin,
        role: result.user.role?.type === "author" ? "author" : "authenticated",
        bio: result.user.bio || "",
      },
    };
    setPersistantAuth(auth);
    return auth;
  }
  return {
    error: result.message,
  };
};

/* novels */
export const getNovels = async (strapiQuery: {
  _start: number;
  "tags.id"?: string;
  title_contains?: string;
  "author.id"?: string;
  _publicationState?: "live" | "preview";
}): Promise<Omit<Novel, "posts">[]> => {
  const novels = await api
    .url("/novels")
    .query(strapiQuery)
    .get()
    .json<components["schemas"]["Novel"][]>();

  return novels.map((novel) => ({
    id: novel.id,
    title: novel.title,
    description: novel.description || "",
    published_at: novel.published_at || "",
    cover: {
      id: novel.cover?.id || "",
      url: novel.cover?.url || "",
    },
    author: {
      id: novel.author?.id || "",
      display_name: novel.author?.username || "",
      bio: novel.author?.bio || "",
    },
    tags:
      novel.tags?.map((tag) => ({
        id: tag.id,
        title: tag.title,
      })) || [],
  }));
};

export const getAllNovels = async ({ skip }: { skip: number }) => {
  const strapiQuery = {
    _start: skip,
  };
  const novels = await getNovels(strapiQuery);
  return novels.sort(() => Math.random() - 0.5);
};
export const getNovelsByTag = async ({
  skip,
  tagId,
}: {
  skip: number;
  tagId: string;
}): Promise<Omit<Novel, "posts">[]> => {
  const strapiQuery = {
    _start: skip,
    "tags.id": tagId,
  };
  return getNovels(strapiQuery);
};

export const getNovelsBySearch = async ({
  skip,
  search,
}: {
  skip: number;
  search: string;
}): Promise<Omit<Novel, "posts">[]> => {
  const strapiQuery = {
    _start: skip,
    title_contains: search,
  };
  return getNovels(strapiQuery);
};

export const getNovelsByUser = async ({
  skip,
  userId,
}: {
  skip: number;
  userId: string;
}): Promise<Omit<Novel, "posts">[]> => {
  const strapiQuery: {
    _start: number;
    "author.id": string;
    _publicationState: "live" | "preview";
  } = {
    _start: skip,
    "author.id": userId,
    _publicationState: "preview",
  };
  return getNovels(strapiQuery);
};

export const getNovelById = async (novelId: string): Promise<Novel | Error> => {
  const novel = await authorizedApi
    .url(`/novels/${novelId}`)
    .get()
    .json<components["schemas"]["Novel"] | Error>();
  if (novel instanceof Error) {
    return novel;
  }
  return {
    id: novel.id,
    title: novel.title,
    description: novel.description || "",
    cover: {
      id: novel.cover?.id || "",
      url: novel.cover?.url || "",
    },
    author: {
      id: novel.author?.id || "",
      display_name: novel.author?.username || "",
      bio: novel.author?.bio || "",
    },
    tags: novel.tags || [],
    published_at: novel.published_at || "",
    posts:
      novel.posts?.map((post) => ({
        id: post.id,
        title: post.title,
        author: post.author,
        published_at: post.published_at || "",
      })) || [],
  };
};

export const addNovel = async (
  title: string,
  description: string,
  authorId: string,
  tags: string[],
  cover: string
): Promise<Novel | Error> => {
  const postBody = {
    title,
    description,
    author: authorId,
    tags,
    cover,
  };
  const novel = await authorizedApi
    .url(`/novels`)
    .post(postBody)
    .json<components["schemas"]["Novel"] | Error>();
  if (novel instanceof Error) {
    return novel;
  }
  return {
    id: novel.id,
    title: novel.title,
    published_at: novel.published_at || "",
    description: novel.description || "",
    cover: {
      id: novel.cover?.id || "",
      url: novel.cover?.url || "",
    },
    author: {
      id: novel.author?.id || "",
      display_name: novel.author?.username || "",
      bio: novel.author?.bio || "",
    },
    posts: novel.posts || [],
    tags: novel.tags || [],
  };
};

export const updateNovel = async (
  novelId: string,
  title: string,
  description: string,
  authorId: string,
  tags: string[],
  cover: string
) => {
  const postBody = {
    title,
    description,
    author: authorId,
    tags,
    cover,
  };
  const novel = await authorizedApi
    .url(`/novels/${novelId}`)
    .put(postBody)
    .json<components["schemas"]["Novel"] | Error>();
  if (novel instanceof Error) {
    return novel;
  }
  return {
    id: novel.id,
    title: novel.title,
    published_at: novel.published_at || "",
    description: novel.description || "",
    cover: {
      id: novel.cover?.id || "",
      url: novel.cover?.url || "",
    },
    author: {
      id: novel.author?.id || "",
      display_name: novel.author?.username || "",
    },
    posts: novel.posts || [],
    tags: novel.tags || [],
  };
};

/* posts */
type PostResponse = components["schemas"]["Post"] & {
  next_id?: string;
  prev_id?: string;
};
export const getPostById = async (postId: string): Promise<Post | Error> => {
  const post = await authorizedApi
    .url(`/posts/${postId}`)
    .get()
    .json<PostResponse | Error>();
  if (post instanceof Error) {
    return post;
  }
  return {
    id: post.id,
    title: post.title,
    published_at: post.published_at || "",
    content: post.content,
    novel: {
      id: post.novel?.id || "",
      title: post.novel?.title || "",
      author: {
        id: post.author?.id || "",
        display_name: post.author?.username || "",
        bio: post.author?.bio || "",
      },
    },
    prev_id: post.prev_id,
    next_id: post.next_id,
  };
};

export const addNovelPost = async (
  novelId: string,
  title: string,
  content: string,
  authorId: string,
  is_published: boolean
): Promise<Post | Error> => {
  const postBody = {
    title,
    content,
    novel: novelId,
    author: authorId,
    is_published,
  };
  const post = await authorizedApi
    .url(`/posts`)
    .post(postBody)
    .json<PostResponse | Error>();
  if (post instanceof Error) {
    return post;
  }
  return {
    id: post.id,
    title: post.title,
    published_at: post.published_at || "",
    content: post.content,
    novel: {
      id: post.novel?.id || "",
      title: post.novel?.title || "",
      author: {
        id: post.author?.id || "",
        display_name: post.author?.username || "",
        bio: post.author?.bio || "",
      },
    },
    prev_id: post.prev_id,
    next_id: post.next_id,
  };
};

export const updateNovelPost = async (
  postId: string,
  novelId: string,
  title: string,
  content: string,
  authorId: string,
  is_published: boolean
): Promise<Post | Error> => {
  const postBody = {
    title,
    content,
    novel: novelId,
    author: authorId,
    is_published,
  };
  const post = await authorizedApi
    .url(`/posts/${postId}`)
    .put(postBody)
    .json<PostResponse | Error>();
  if (post instanceof Error) {
    return post;
  }
  return {
    id: post.id,
    title: post.title,
    published_at: post.published_at || "",
    content: post.content,
    novel: {
      id: post.novel?.id || "",
      title: post.novel?.title || "",
      author: {
        id: post.author?.id || "",
        display_name: post.author?.username || "",
        bio: post.author?.bio || "",
      },
    },
    prev_id: post.prev_id,
    next_id: post.next_id,
  };
};

export const getTags = async (): Promise<Tag[]> => {
  const tags = await api
    .url("/categories")
    .get()
    .json<components["schemas"]["Category"][]>();
  return tags.map((tag) => ({
    id: tag.id,
    title: tag.title,
  }));
};

export const getImageUrl = (path: string) => {
  if (!path) {
    return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
  }
  return `${API_BASE}${path}`;
};

export const uploadFile = async (file: File) => {
  const body = {
    files: file,
  };
  const result = await authorizedApi
    .url("/upload")
    .formData(body)
    .post()
    .json<CoverMedia[] | Error>();
  return result instanceof Error ? result : result[0];
};
