import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";

const PREFIX = "Footer";

const classes = {
  footer: `${PREFIX}-footer`,
};

const Root = styled("footer")(({ theme }) => ({
  [`&.${classes.footer}`]: {
    // backgroundColor: theme.palette.background.paper,
    // marginTop: theme.spacing(8),
    padding: theme.spacing(6, 0),
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © WebSoSo"} {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Root className={classes.footer}>
      <Container maxWidth="lg">
        <Copyright />
      </Container>
    </Root>
  );
}
