import React from "react";
import { Control, useWatch } from "react-hook-form";

interface Props {
  control: Control<{ content: string }>;
}

export default function Counter({ control }: Props) {
  const content = useWatch({
    control,
    name: "content",
    defaultValue: "",
  });
  return <p>글자수: {content.length}</p>;
}
