import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { navigate, RouteComponentProps } from "@reach/router";
import React, { useEffect, useMemo, useState } from "react";
import { getNovelsByUser } from "services/api";
import { User } from "services/models";

const PREFIX = "SingleNovel";

const classes = {
  novelGrid: `${PREFIX}-novelGrid`,
  novelTitle: `${PREFIX}-novelTitle`,
  novelDescription: `${PREFIX}-novelDescription`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.novelGrid}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.novelTitle}`]: {
    wordBreak: "keep-all",
  },

  [`& .${classes.novelDescription}`]: {
    wordBreak: "keep-all",
    overflowWrap: "break-word",
  },
}));

interface MatchingProps {
  authorId: string;
  children: React.ReactNode;
}

export default function SingleAuthor({
  authorId,
}: RouteComponentProps<MatchingProps>) {
  const [author, setAuthor] = useState<Pick<User, "display_name" | "bio">>();
  useEffect(() => {
    if (!authorId) return;
    getNovelsByUser({ skip: 0, userId: authorId }).then((result) => {
      if (result instanceof Error) {
        alert(result.message);
        navigate("/");
      } else {
        setAuthor(result[0].author);
      }
    });
  }, [authorId]);

  const descriptionParagraphs = useMemo(() => {
    const urlPattern =
      /(^|[\s\n]|<[A-Za-z]*\/?>)((?:https?):\/\/[-A-Z0-9+\u0026\u2019@#/%?=()~_|!:,.;]*[-A-Z0-9+\u0026@#/%=~()_|])/gi;

    return author
      ? author.bio.split("\n").map((line, idx) => (
          <Typography
            key={idx}
            paragraph
            className={classes.novelDescription}
            dangerouslySetInnerHTML={{
              __html: line.replace(
                urlPattern,
                "$1<a href='$2' target='_blank' noopener>$2</a>"
              ),
            }}
          />
        ))
      : "";
  }, [author]);
  return (
    <StyledGrid container spacing={4} className={classes.novelGrid}>
      <Grid item xs={12}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          className={classes.novelTitle}
        >
          {author ? author.display_name : <Skeleton />}
        </Typography>
        <Box paddingTop={2}>{descriptionParagraphs}</Box>
      </Grid>
    </StyledGrid>
  );
}
