import AccountCircle from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { Link as RouterLink, navigate } from "@reach/router";
import React, { useState } from "react";
import { logout } from "services/api";
import { User } from "services/models";

const PREFIX = "Header";

const classes = {
  toolbar: `${PREFIX}-toolbar`,
  toolbarTitle: `${PREFIX}-toolbarTitle`,
};

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  [`&.${classes.toolbar}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.toolbarTitle}`]: {
    flex: 3,
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 4),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
    },
  },
}));

export default function Header({ user }: { user?: User }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    window.location.reload();
  };

  const onSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const searchInput = e.currentTarget.elements.namedItem(
      "search"
    ) as HTMLInputElement;
    if (!searchInput.value) {
      navigate("/");
    } else {
      navigate(`/search/${searchInput.value}`);
    }
  };
  return (
    <StyledToolbar className={classes.toolbar} disableGutters>
      <Box flex={2}>
        <form onSubmit={onSearch}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              name="search"
              placeholder="검색"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        </form>
      </Box>
      <Link
        component={RouterLink}
        to="/"
        variant="h5"
        color="inherit"
        align="center"
        underline="none"
        className={classes.toolbarTitle}
      >
        WebSoSo
      </Link>
      <Box
        flex={2}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        {user ? (
          <>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem disabled>{`${user.display_name} ${
                user.is_active ? "" : "(미승인)"
              }`}</MenuItem>
              {user.role === "author" && (
                <MenuItem component={RouterLink} to="/my">
                  내 작품 관리
                </MenuItem>
              )}
              <MenuItem onClick={handleLogout}>로그아웃</MenuItem>
            </Menu>
          </>
        ) : (
          <Button size="small" component={RouterLink} to="/login">
            로그인
          </Button>
        )}
      </Box>
    </StyledToolbar>
  );
}
