import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link as RouterLink, navigate } from "@reach/router";
import format from "date-fns/format";
import React from "react";
import { Post } from "services/models";

type PostInList = Omit<Post, "content" | "novel">;
interface PostListProps {
  posts: PostInList[];
  editable?: boolean;
}
export default function PostList({ posts, editable }: PostListProps) {
  return (
    <Grid item xs={12}>
      {posts.length > 0 && (
        <Button
          variant="outlined"
          size="large"
          fullWidth
          component={RouterLink}
          to={`/posts/${posts[posts.length - 1].id}`}
        >
          1화부터 보기
        </Button>
      )}
      <List>
        {posts.map((post) => {
          const postDate = post.published_at
            ? format(new Date(post.published_at), "yyyy.MM.dd")
            : "비공개";
          return (
            <ListItem
              key={post.id}
              button
              divider
              component={RouterLink}
              to={`/posts/${post.id}`}
              secondaryAction={
                editable && (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/posts/${post.id}/edit`);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                )
              }
            >
              <ListItemText primary={post.title} secondary={postDate} />
            </ListItem>
          );
        })}
      </List>
    </Grid>
  );
}
